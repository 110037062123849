import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {

  constructor() { }
  // nums= [4, 5, 6, 7, 1, 2, 3];
  // k =3
  ngOnInit(): void {

 

  //  console.log( this.roate(this.nums, this.k))
   
  }
  
  // roate(nums:any, k:number){
    
  //   for(let i=0; i<k ; i++){
  //     let poped =nums.pop();
  //     console.log("poped",poped);
  //    nums.unshift(poped);
      
  //   }
    
  //   return nums;
  // }
  

}
