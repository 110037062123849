import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-internet-down-modal',
  templateUrl: './internet-down-modal.component.html',
  styleUrls: ['./internet-down-modal.component.scss']
})
export class InternetDownModalComponent implements OnInit {

  constructor(public bsModalRef: BsModalRef) {}
  ngOnInit(): void {
  }

}
