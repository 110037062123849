import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class JwtInterceptorService implements HttpInterceptor {
    api = environment.apiEndPoint;

    constructor(private router: Router) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        const token = JSON.parse(localStorage.getItem('usertoken'));
        const temptoken = JSON.parse(localStorage.getItem('temptoken'));

        if (this.router.url === ('/updatePassword')) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${temptoken}`,
                    'Access-Control-Allow-Origin': '*'
                }
            });
            return next.handle(request);
        }
        if (token) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`,
                    'Access-Control-Allow-Origin': '*'
                }
            });
        }

        return next.handle(request);
    }
}
