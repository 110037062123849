<!--rating with half stars-->
<!-- 
<div class="stars text-center"> 
    <span class="star" [class.half]="halfClass1" [class.on]="fullClass1" 
    [class.green]="greenColor"
     [class.orange]="orangeColor"
     [class.red]="redColor"
    ></span>
    <span class="star" [class.half]="halfClass2" [class.on]="fullClass2"
    [class.green]="greenColor"
     [class.orange]="orangeColor"
     [class.red]="redColor"></span>
    <span class="star" [class.half]="halfClass3" [class.on]="fullClass3"
    [class.green]="greenColor"
     [class.orange]="orangeColor"
     [class.red]="redColor"></span>
    <span class="star" [class.half]="halfClass4" [class.on]="fullClass4"
    [class.green]="greenColor"
     [class.orange]="orangeColor"
     [class.red]="redColor"></span>
    <span class="star" [class.half]="halfClass5" [class.on]="fullClass5"
    [class.green]="greenColor"
     [class.orange]="orangeColor"
     [class.red]="redColor"></span>
</div> -->


<!--rating with only full stars-->
<div class="stars text-center"> 
    <span class="star"  [class.on]="fullClass1" 
    [class.green]="greenColor"
     [class.orange]="orangeColor"
     [class.red]="redColor"
    ></span>
    <span class="star" [class.on]="fullClass2"
    [class.green]="greenColor"
     [class.orange]="orangeColor"
     [class.red]="redColor"></span>
    <span class="star"  [class.on]="fullClass3"
    [class.green]="greenColor"
     [class.orange]="orangeColor"
     [class.red]="redColor"></span>
    <span class="star"  [class.on]="fullClass4"
    [class.green]="greenColor"
     [class.orange]="orangeColor"
     [class.red]="redColor"></span>
    <span class="star" [class.on]="fullClass5"
    [class.green]="greenColor"
     [class.orange]="orangeColor"
     [class.red]="redColor"></span>
</div>

