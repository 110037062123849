import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { RegisterService } from 'src/app/shared/auth/register.service';

@Component({
  selector: 'app-verify-phone',
  templateUrl: './verify-phone.component.html',
  styleUrls: ['./verify-phone.component.scss']
})
export class VerifyPhoneComponent implements OnInit {
  showInput = false;
  modalRef: BsModalRef;
  updatemobileForm: FormGroup;
  pinCodeForm: FormGroup;
  showcodeBox =  false;
  metaData:any;
  user:{};
  constructor(private modalService: BsModalService, private fb:FormBuilder, private register: RegisterService, 
    private router: Router, private notifier: NotifierService) 
    {}


  ngOnInit(): void {
    this.getMetaData();
    this.updatemobileForm = this.fb.group({
      operator:['', Validators.required],
      phoneNumber:['', Validators.required]
    });

    this.pinCodeForm = this.fb.group({
      userPin:['', Validators.required]
    })
  }

  getMetaData(){
    this.register.getCityProvinceMetaData().subscribe( res=>{
        this.metaData = res['data'];
    });
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  showPhoneField($event){
    if($event.target.checked){
        this.showInput =  true;
    }else{
      this.showInput = false;
    }
  }

  submit(verifyPhone){
    let form = this.updatemobileForm.value;
    const phoneNumber = form.phoneNumber;
    const updatePhone = true;
    const networkId = Number(form.operator);
    const data = {phoneNumber, updatePhone, networkId};
    this.register.sendVerificationCode(data).subscribe( res=>{
        this.openModal(verifyPhone);
        this.showcodeBox =true;
        // this.router.navigate(['/'])
    }, err=>{

    })

  }

  sendCode(){
      const user = JSON.parse(localStorage.getItem('user'));

      const phoneNumber = user?.userName;
      const updatePhone = false;
      const networkId = 1;
      const data = {phoneNumber, updatePhone, networkId};
      this.register.sendVerificationCode(data).subscribe( res=>{
          this.showcodeBox =true;
          
          this.notifier.notify('success', res['message']);
      }, err=>{
        this.notifier.notify('error', err['message']);
      })
  }

  verifyPinCode(){
    let userPin = this.pinCodeForm.value;

    if(this.pinCodeForm.valid){
      this.register.verifyPinCode(userPin).subscribe(res=>{
        this.notifier.notify('success', res['message']);
       
            this.router.navigate(['/uploadDocs']);
      }, error=>{
        
        this.notifier.notify('error', error.message);
      });
    }else{
        

    }
   
  }

}
