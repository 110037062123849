import { Component, Input, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-star-rating',
  templateUrl: './star-rating.component.html',
  styleUrls: ['./star-rating.component.scss']
})
export class StarRatingComponent implements OnInit {
  @Input('rating') rating!: any;
  halfClass1:boolean= false;
  halfClass2:boolean= false;
  halfClass3:boolean= false;
  halfClass4:boolean= false;
  halfClass5:boolean= false;
  fullClass1:boolean= false;
  fullClass2:boolean= false;
  fullClass3:boolean= false;
  fullClass4:boolean= false;
  fullClass5:boolean= false;
  redColor:boolean= false;
  greenColor:boolean= false;
  orangeColor:boolean= false;
  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes:SimpleChanges){
      if(changes['rating'].currentValue){
        console.log("rating", changes['rating'].currentValue)
          this.getRating();
      }
  }

  color= 'green';
//   getRating(){

//       if(this.rating < 1){
//         this.halfClass1 = true;
//         this.redColor = true;
//       }else if(this.rating == 1){
//           this.fullClass1 = true;
//           this.redColor = true;
//       }else if(this.rating > 1 && this.rating < 2){
//           this.fullClass1 = true;
//           this.halfClass2= true;
//           this.orangeColor = true;
//       }else if(this.rating == 2){
//           this.fullClass1 = true;
//           this.fullClass2= true;
//           this.orangeColor = true;
//       }else if(this.rating > 2 && this.rating < 3){
//           this.fullClass1 = true;
//           this.fullClass2= true;
//           this.halfClass3= true;
//           this.orangeColor = true;
//       }else if(this.rating == 3){
//           this.fullClass1 = true;
//           this.fullClass2= true;
//           this.fullClass3= true;
//           this.orangeColor = true;
//       }else if(this.rating > 3 && this.rating < 4){
//           this.fullClass1 = true;
//           this.fullClass2= true;
//           this.fullClass3= true;
//           this.halfClass4= true;
//           this.greenColor = true;
//       }else if(this.rating == 4){
//           this.fullClass1 = true;
//           this.fullClass2= true;
//           this.fullClass3= true;
//           this.fullClass4= true;
//           this.greenColor = true;
//       }else if(this.rating > 4 && this.rating < 5){
//           this.fullClass1 = true;
//           this.fullClass2= true;
//           this.fullClass3= true;
//           this.fullClass4= true;
//           this.halfClass5= true;
//           this.greenColor = true;
//       }else if(this.rating == 5){
//           this.fullClass1 = true;
//           this.fullClass2= true;
//           this.fullClass3= true;
//           this.fullClass4= true;
//           this.fullClass5= true;
//           this.greenColor = true;
//       }
    
//   }
  getRating(){

      if(this.rating > 90){
        this.fullClass1 = true;
        this.fullClass2= true;
        this.fullClass3= true;
        this.fullClass4= true;
        this.fullClass5= true;
        this.greenColor = true;
      }else if(this.rating > 80){
        this.fullClass1 = true;
        this.fullClass2= true;
        this.fullClass3= true;
        this.fullClass4= true;
        this.greenColor = true;
      }else if(this.rating > 70){
        this.fullClass1 = true;
        this.fullClass2= true;
        this.fullClass3= true;
        this.orangeColor = true;
      }else if(this.rating > 60){
        this.fullClass1 = true;
        this.fullClass2= true;
        this.orangeColor = true;
      }else if(this.rating > 0){
          this.fullClass1 = true;
          this.redColor = true;
      }
    
    
  }

}
