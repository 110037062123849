import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'unique'
})
export class UniquePipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): unknown {
    // Remove the duplicate elements
    var art = value.map(x=>{
      return x.ArticleTags.map(y=>{ return y.value;});;
  }).reduce((acc,ele,i)=>{
      acc = acc.concat(ele);
      return acc;
  });
  return new Set(art);
  }

}
