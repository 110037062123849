import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaginationComponent } from './components/pagination/pagination.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { RemainCharsPipe } from './helpers/remain-chars.pipe';
import { DisableControlDirective } from './helpers/disable-control.directive';
import { MultilinePipe } from './helpers/multiline.pipe';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { DragNDropDirective } from './helpers/drag-ndrop.directive';
import { InternetDownModalComponent } from './components/internet-down-modal/internet-down-modal.component';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { UniquePipe } from './helpers/unique.pipe';
import { NotFoundComponent } from './components/not-found/not-found.component'
import { RouterModule } from '@angular/router';
import { DoubleClickDirective } from './helpers/double-click.directive';
import { StarRatingComponent } from './components/star-rating/star-rating.component';
// import { RatingModule } from 'ng-starrating';

@NgModule({
  declarations: [PaginationComponent, RemainCharsPipe, DisableControlDirective, MultilinePipe, DragNDropDirective, InternetDownModalComponent, UniquePipe, NotFoundComponent, DoubleClickDirective,
    StarRatingComponent ],
  imports: [
    CommonModule, BsDatepickerModule.forRoot(), NgxDatatableModule, ModalModule.forRoot(),
    TooltipModule.forRoot(), PopoverModule.forRoot(),TypeaheadModule.forRoot(),RouterModule,
  ], exports: [
    PaginationComponent, FormsModule, ReactiveFormsModule, BsDatepickerModule, DisableControlDirective, MultilinePipe,
     NgxDatatableModule, ModalModule,NotFoundComponent,
    TooltipModule, PopoverModule, TypeaheadModule,InternetDownModalComponent,UniquePipe, DoubleClickDirective,
    StarRatingComponent
  ]
})
export class SharedModule { }
