import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxMaskModule } from 'ngx-mask'
import { NgxSpinnerModule } from "ngx-spinner";
import { JwtInterceptorService } from './shared/auth/jwt.interceptor.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { NotifierModule } from "angular-notifier";
import { ErrorInterceptorService } from './shared/auth/error.interceptor.service';
import { GlobalErrorHandler } from './shared/auth/global-error-handler';

@NgModule({
  declarations: [
    AppComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule, NgxMaskModule.forRoot(), HttpClientModule, NgxSpinnerModule, SharedModule,
    NotifierModule.withConfig({
      position: {

        horizontal: {
          position: 'middle',
          distance: 12
        },
        vertical: {
          position: 'top',
          distance: 12,
          gap: 10
        }
      },
      theme: "material"
    }),

  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptorService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptorService, multi: true },
    // {provide: ErrorHandler, useClass: GlobalErrorHandler}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
